import { EnergyEntry, EnergyUsageType, FuelEntry } from "../@types";
import { msg } from "../locale/texts";
import { generateString } from "../utils";

export const entrySpecialOptions = [
  {
    name: "Nein",
    value: "false",
  },
  {
    name: "Ja",
    value: "true",
  },
];

export const energyTypes = [
  {
    name: msg.energyTypes.electricity,
    id: 0,
  },
  {
    name: msg.energyTypes.gas,
    id: 1,
  },
];

export const fuelTypes = [
  {
    name: msg.fuelTypes.benzine,
    constant: 0.482,
    priceConstant: 1.2984,
  },
  {
    name: msg.fuelTypes.diesel,
    constant: 0.397,
    priceConstant: 1.1964,
  },
];

export const usageMonths = [
  {
    id: 0,
    name: "Feb",
    num: 2,
    year: 22,
  },
  {
    id: 1,
    name: "Mär",
    num: 3,
    year: 22,
  },
  {
    id: 2,
    name: "Apr",
    num: 4,
    year: 22,
  },
  {
    id: 3,
    name: "Mai",
    num: 5,
    year: 22,
  },
  {
    id: 4,
    name: "Jun",
    num: 6,
    year: 22,
  },
  {
    id: 5,
    name: "Jul",
    num: 7,
    year: 22,
  },
  {
    id: 6,
    name: "Aug",
    num: 8,
    year: 22,
  },
  {
    id: 7,
    name: "Sep",
    num: 9,
    year: 22,
  },
];

export const emptyEnergyUsageEntry: EnergyUsageType = {
  id: 0,
  price: undefined,
  consumption: undefined,
};

export const emptyFuelEntry: FuelEntry = {
  name: "",
  consumption: undefined,
  constant: 0,
  priceConstant: 0,
  averagePrice: undefined,
};

export const createFuelEntries = () => {
  return fuelTypes.map((fuelType) => {
    return {
      ...emptyFuelEntry,
      ...fuelType,
    };
  });
};

export const createEnergyMonthEntries = () => {
  return usageMonths.map((month) => {
    return {
      ...emptyEnergyUsageEntry,
      id: month.id,
    };
  });
};

export const emptyEnergyEntry: EnergyEntry = {
  key: "",
  id: "",
  special: "",
  type: "",
  price: 0,
  consumption: undefined,
  isLinked: true,
  hideFromStep2: false,
  usage: createEnergyMonthEntries(),
};

export const defaultEnergyEntries: EnergyEntry[] = [
  {
    ...emptyEnergyEntry,
    type: energyTypes[0].name,
  },
  {
    ...emptyEnergyEntry,
    type: energyTypes[1].name,
  },
];

export const createEnergyEntries = () => {
  return defaultEnergyEntries.map((energyEntry) => {
    return {
      ...emptyEnergyEntry,
      ...energyEntry,
      key: generateString(),
    };
  });
};

export const getExcelExportDefaultData = () => {
  let defaultExcelData: Record<string, any> = {};

  energyTypes.map((type) => {
    defaultExcelData[type.name] = {
      [`Ace${type.name}verbrauchimfoerderzeitraum`]: 0,
      [`AceDurchschnittspreis${type.name.toLowerCase()}zeitraum`]: 0,
      [`AceDurchschnittspreis${type.name.toLowerCase()}vergleich`]: 0,
    };
  });

  defaultExcelData[msg.fuelTypes.title] = {
    [`AceTreibstoffeverbrauchimfoerderzeitraum`]: 0,
    [`AceDurchschnittspreistreibstoffezeitraum`]: 0,
  };

  return defaultExcelData;
};
