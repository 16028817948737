import React, { FC, ReactNode, useState, useRef } from 'react'
import { useClickOutside } from '../hooks/useClickOutside'

type ButtonProps = {
    onClick?: () => any
    children: ReactNode
    disableClass?: boolean
    className?: string
    wrapperClassName?: string
    disabled?: boolean
    confirm?: boolean
}

export const Button: FC<ButtonProps> = ({
    onClick,
    children,
    disableClass,
    className,
    wrapperClassName,
    disabled,
    confirm
}) => {
    const divRef = useRef(null)
    const [showConfirm, setShowConfirm] = useState(false)

    const handleOnClick = () => {
        if (!confirm) {
            if (onClick) {
                onClick()
            }
            return
        }

        setShowConfirm(true)
    }

    if (confirm) {
        useClickOutside({ ref: divRef, action: () => setShowConfirm(false) })
    }

    return (
        <div className={`double-confirm overflow-hidden ${wrapperClassName}`} ref={divRef}>
            <div className="double-confirm-inner relative transform transition duration-500 ease-in-out">

                <button
                    className={`${!disableClass ? `bg-primary-1 hover:bg-primary-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline` : ''} ${className} ${disabled ? 'hover:bg-gray-400' : ''}`}
                    onClick={handleOnClick}
                    disabled={disabled}
                >
                    {children}
                </button>

                {
                    confirm
                        ? <button onClick={onClick} onMouseOut={() => setShowConfirm(false)} className={`absolute w-full h-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${showConfirm ? '-translate-x-full' : ''}`}>
                            Bestätigen
                        </button>
                        : null
                }
            </div>
        </div>
    )
}
