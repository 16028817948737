import { useEffect, RefObject } from "react";

export const useClickOutside = ({
  ref,
  action,
}: {
  ref: RefObject<any>;
  action: () => void;
}) => {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};
