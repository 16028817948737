export const msg = {
  resetButton: "Werte zurücksetzen",
  layout: {
    header: "Energiekostenzuschuss - Berechnungshilfe",
    text: "In wenigen Schritten zur Berechnung Ihres möglichen Zuschusses in der Basisstufe (für Unternehmen mit Energie-, Strom- und Treibstoffbeschaffungskosten bis EUR 16 Mio.)",
  },
  step1: {
    title:
      "Schritt 1: Berechnung des Durchschnittsarbeitspreises in der Vergleichsperiode 2021",
    text: [
      "Um die Preissteigerungen berechnen zu können, benötigen wir im ersten Schritt Angaben aus der letzten Jahresabrechnung Ihres Strom- und Erdgaslieferanten oder aus Ihrem Lastprofilzähler (sofern bei Ihnen ein Lastprofilzähler installiert wurde).",
      "<b>Mehrere Zählpunkte mit identem Tarif</b> können in einem <b>Sammeleintrag</b> mit der fiktiven Zählpunktnummer 9999 zusammengefasst werden.",
    ],
  },
  step2: {
    title:
      "Schritt 2: Berechnung des Durchschnittsarbeitspreises im Förderungszeitraum und der Preissteigerung",
    text: [
      "Im zweiten Schritt ersuchen wir Sie um Angaben zum Arbeitspreis und zum Strom/-Erdgasverbrauch im Förderungszeitraum. Der Förderungszeitraum erstreckt sich von 1. Februar bis 30. September 2022.",
      "<b>Mehrere Zählpunkte mit identem Tarif</b> können in einem <b>Sammeleintrag</b> mit der fiktiven Zählpunktnummer 9999 zusammengefasst werden.",
    ],
  },
  step3: {
    title: "Schritt 3: Treibstoffverbrauch und Durchschnittspreis",
    text: [],
  },
  step4: {
    title: "Schritt 4: Ihr Zuschuss",
    text: [],
  },
  stepDetails:
    "Für detaillierte Berechnungsinformationen klicken Sie bitte auf das + auf der linken Seite.",
  addPoint: "Zählpunkt hinzufügen",
  energyEntry: {
    id: "Zählpunktnummer (nur die letzten vier Stellen)",
    idText:
      "Die Zählpunktnummer beginnt mit AT und kann der Abrechnung entnommen werden. Bitte geben Sie nur die letzten vier Stellen Ihrer Zählpunktnummer an.",
    special:
      "Lastprofilzähler oder genormtes intelligentes Messgerät verfügbar",
    specialText:
      "Ist ein Lastprofilzähler (Messgerät für Großkunden) oder ein genormtes intelligentes Messgerät mit monatlicher Abrechnung verfügbar, erfolgt die Zuschussberechnung gem. Pkt. 9.1.1 der Richtlinie.",
    type: "Energieart",
    typeText: "Bitte geben Sie die jeweilige Energieart an.",
    price: "Nettorechnungsbetrag",
    priceText1:
      "Der Nettorechnungsbetrag bezeichnet die Kosten für die verbrauchten Kilowattstunden (kWh)",
    priceText2: "(exkl. Steuern, Abgaben, Netzentgelte, etc.).",
    consumptionYes: "verbrauch in kWh im Kalenderjahr 2021",
    consumptionNo: "verbrauch in kWh gem. letzter Jahresabrechnung",
    consumptionText1Yes: "Bitte geben Sie den ",
    consumptionText2Yes:
      "verbrauch für den gesamten Zeitraum von 1. Jänner 2021 und 31. Dezember 2021 an.",
    consumptionText1No: "Den",
    consumptionText2No:
      "verbrauch entnehmen Sie bitte der letzten Jahresabrechnung, deren Abrechnungszeitraum zwischen 31. Jänner 2021 und 31. Jänner 2022 endet.",
  },
  fuelEntry: {
    consumption: "verbrauch im Förderungszeitraum",
    consumptionText:
      "Der Förderungszeitraum erstreckt sich von 1. Februar 2022 bis 30. September 2022.",
    averagePriceBrutto:
      "Durchschnittlicher Literpreis (brutto) im Förderungszeitraum in EUR",
    averagePriceNetto:
      "Durchschnittlicher Literpreis (netto) im Förderungszeitraum in EUR",
    averageBruttoPriceText:
      "Der Bruttopreis enthält die MÖSt und USt und entspricht der Preisangabe an der Zapfsäule.",
    averageBruttoPriceTextError1: "Da keine Preissteigerung vorliegt, ist der",
    averageBruttoPriceTextError2: "verbrauch nicht förderungsfähig.",
    averageNettoPriceText:
      "Der Nettopreis wurde anhand des eingegebenen Bruttopreises errechnet und ist der für die Förderung relevante Preis.",
  },
  usageEntry: {
    id: "Zählpunktnummer (nur die letzten vier Stellen)",
    idText:
      "Bitte geben Sie nur die letzten vier Stellen Ihrer Zählpunktnummer an.",
    special:
      "Lastprofilzähler oder genormtes intelligentes Messgerät verfügbar",
    type: "Energieart",
    typeText: "Bitte geben Sie die jeweilige Energieart an.",
    price1: "Arbeitspreis pro kWh",
    price2: "in EUR",
    priceText: "kosten exkl. Steuern, Abgaben, Netzentgelte, etc.",
    consumption1No: "Aliquoter",
    consumption2No: "verbrauch in kWh von 1. Februar bis 30. September 2022",
    consumption1Yes: "verbrauch in kWh von 1. Februar bis 30. September 2022",
    consumptionTextYes:
      "Bitte ergänzen Sie die monatlichen Verbrauchswerte gem. Lastprofilzähler",
    consumptionTextNo:
      "Vorbefüllte Verbrauchswerte wurden aliquot (d.h. 1/12) aus dem Jahr 2021 übernommen (sofern vorhanden).",
  },
  fuelTypes: {
    title: "Treibstoffe",
    benzine: "Benzin",
    diesel: "Diesel",
  },
  energyTypes: {
    electricity: "Strom",
    gas: "Erdgas",
  },
  energyTotal: {
    price: "Summe der Nettorechnungsbeträge aller Zählpunkte",
    energy: "verbrauch aller Zählpunkte in kWh im Gesamtjahr",
    average: "Errechneter Durchschnittsarbeitspreis aller Zählpunkte",
  },
  energyTotalStep2: {
    consumptionWithoutProfile:
      "verbrauch aller Zählpunkte ohne Lastprofilzähler in kWh",
    consumptionWithProfile:
      "verbrauch aller Zählpunkte mit Lastprofilzähler in kWh",
    consumptionTotal: "verbrauch aller Zählpunkte in kWh",
    averagePriceFunding:
      "Errechneter Durchschnittsarbeitspreis im Förderungszeitraum",
    calculatedPriceComparison:
      "- Errechneter Durchschnittsarbeitspreis im Vergleichszeitraum",
    priceIncrease: "= Preissteigerung des Durchschnittsarbeitspreises",
    consumptionWithoutProfileText1: "Der förderungsfähig",
    consumptionWithoutProfileText2:
      "verbrauch ist für Zählpunkte ohne Lastprofilzähler mit 1.000.000 kWh begrenzt.",
    averagePriceFundingText1:
      "Der Arbeitspreis bezeichnet die Kosten für die verbrauchten Kilowattstunden (kWh)",
    averagePriceFundingText2:
      "(exkl. Gebühren, Steuern, Abgaben, Netzentgelte).",
  },
  grant: {
    title: "Ihr Zuschuss:",
    energyText:
      "Die Zuschusshöhe errechnet sich wie folgt: Preisanstieg x Verbrauch (bei Zählpunkten ohne Lastprofilzähler max. 1.000.000 kWh) x 30 % Zuschussquote.",
    fuelText:
      "Die Zuschusshöhe errechnet sich, indem der Preisanstieg (d.h. der durchschnittliche Nettopreis - 60 Cent) mit dem Verbrauch und der Zuschussquote von 30 % multipliziert wird.",
    total: "Ihr möglicher Gesamtzuschuss",
    totalTextMore:
      "Die Berechnung des Zuschusses (max. 400 TEUR) erfolgt auf Basis Ihrer Angaben, die im Zuge der Antragstellung geprüft werden können.",
    totalTextLess:
      "Der mögliche Gesamtzuschuss liegt unter der betragsmäßigen Untergrenze von EUR 2.000,- .",
  },
  links: {
    backStep1: "Zurück zu Schritt 1",
    nextStep2: "Weiter zu Schritt 2",
    backStep2: "Zurück zu Schritt 2",
    backStep3: "Zurück zu Schritt 3",
    nextStep3: "Weiter zu Schritt 3",
    nextStep4: "Weiter zu Schritt 4",
  },

  export: {
    period: "verbrauch im Förderungszeitraum",
    energy: {
      average1: "Durchschnittsarbeitspreis",
      average2: "im Förderungszeitraum",
    },
    fuel: {
      nettoPeriod1: "Durchschnittsnettopreis",
      nettoPeriod2: "im Förderungszeitraum",
    },
    downloadModal: {
      title: "Bitte geben Sie einen Dateinamen an",
      ruleStartWithDot: "Der Dateiname darf nicht mit einem Punkt beginnen",
      ruleSpecial:
        'Folgende Zeichen sind nicht im Dateinamen erlaubt / : * ? " < >',
      ruleEndWithDot: "Der Dateiname darf nicht mit einem Punkt enden",
      ruleEmpty: "Es muss ein Dateiname eingegeben werden",
    },
  },
};
