import React, { FC, useContext } from 'react'
import { Link } from 'gatsby'
import Logo from '../assets/aws_logo.inline.svg'
import { AppFooter } from './Footer'
import { msg } from '../locale/texts'
import { Button } from '../components/Button'
import { EnergyContext } from '../context/EnergyContext'
import Helmet from 'react-helmet'

type AppProps = {
    children: any
}

const links = [
    {
        name: 'Strom und Gas im Vergleichszeitraum',
        href: '/'
    },
    {
        name: 'Strom und Gas im Förderzeitraum',
        href: '/step2'
    },
    {
        name: 'Treibstoff im Förderzeitraum',
        href: '/step3'
    },
    {
        name: 'Ihr Zuschuss',
        href: '/step4'
    }
]

export const App: FC<AppProps> = ({ children }) => {
    const { isResetting, resetEntries } = useContext(EnergyContext)

    return <main key={`reset-${isResetting}`} className='flex flex-col text-primary-500 '>
        <Helmet
            htmlAttributes={{
                lang: 'de-DE',
            }}
        />
        <div className='sticky top-0 z-50 bg-white  border-b-2 border-primary-500'>
            <div className="container flex flex-col sm:flex-row py-3 px-6">
                <div className="sm:w-2/3 order-last sm:order-first">
                    <h2 className='text-2xl font-bold'>{msg.layout.header}</h2>
                    <p className='text-xs'>{msg.layout.text}</p>
                </div>
                <Link aria-label="Homepage" className="flex sm:justify-end items-center sm:w-1/3" to="/" >
                    <Logo />
                </Link>
            </div>
            <div className="container px-6 mb-3 flex">
                {links && links.map((link, id) =>
                    <Link
                        key={`HEADER_LINK-${id}`}
                        className='mx-2 rounded hover:underline hover:underline-white text-white first:ml-0 bg-primary-1 hover:bg-primary-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                        to={link.href}
                        activeClassName="bg-primary-500"
                    >
                        <Button disableClass>
                            {link.name}
                        </Button>
                    </Link>
                )}

                <Button
                    disabled={isResetting}
                    onClick={() => resetEntries()}
                    disableClass
                    wrapperClassName='ml-auto'
                    className='border border-primary-1 text-primary-1 hover:border-primary-500 hover:text-white hover:bg-primary-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                    confirm
                >
                    {msg.resetButton}
                </Button>
            </div>
        </div>

        <div className='container pt-5 px-6'>
            {children}
        </div>

        <AppFooter />
    </main >
}