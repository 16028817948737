import { Workbook } from "exceljs";
import { EnergyEntry } from "../@types";
import { saveAs } from "file-saver";

export const exportDataToJSON = (data: any) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(data)
  )}`;
  const link = document.createElement("a");
  link.href = jsonString;
  link.download = "Energiekostenzuschuss.json";

  link.click();
};

export const exportDataToEXCEL = async (
  raw_data_object: Record<string, any>,
  path: string,
  fileName: string = `default`
) => {
  const raw_data = Object.values(raw_data_object).flat();

  var workbook = new Workbook();

  const response = await fetch(path);

  if (response && response.body) {
    await workbook.xlsx
      .load(await response.arrayBuffer())
      .then((data: any) => {
        const worksheet = workbook.getWorksheet("Import");

        const rows: any[] = [];

        raw_data.map((entry) => {
          Object.keys(entry).map((key) => {
            rows.push([key, entry[key] > 0 ? entry[key] : 0]);
          });
        });

        // Added values
        worksheet.addTable({
          name: "TableWithValues",
          ref: "A1",
          headerRow: true,
          style: {
            showRowStripes: true,
          },
          columns: [
            { name: "Field", filterButton: true },
            { name: "Key", filterButton: true },
          ],
          rows,
        });

        // Added vaidation data
        worksheet.addTable({
          name: "ValidationTable",
          ref: "D1",
          headerRow: true,
          style: {
            showRowStripes: true,
          },
          columns: [
            { name: "Validation", filterButton: true },
            { name: "Value", filterButton: true },
          ],
          rows: [
            ["Berechnungsart", 100000000],
            ["Kontrollfeld", "OK"],
            ["Version", "1.4.2"],
          ],
        });

        worksheet.state = "veryHidden";
      })
      .catch((e: Error) => {
        console.log(`readFile`);
        console.log(e);
      });

    await workbook.xlsx
      .writeBuffer()
      .then((data: any) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        saveAs(blob, `${fileName}.xlsx`);
      })
      .catch((e: Error) => {
        console.log(`writeBuffer`);
        console.log(e);
      });
  }
};

export const printNumber: (
  number?: number | string,
  decimals?: number,
  defaultValue?: number | string | undefined
) => string | undefined = (
  number?: number | string,
  decimals: number = 2,
  defaultValue?: number | string | undefined
) => {
  if (!number) {
    if (typeof defaultValue == "number") {
      return formatNumber(defaultValue, decimals).toLocaleString("de-DE", {
        minimumFractionDigits: decimals,
      });
    }

    return defaultValue;
  }

  number = formatNumber(number, decimals);

  return number.toLocaleString("de-DE", { minimumFractionDigits: decimals });
};

export const formatNumberForInput: (
  number?: number | string,
  decimals?: number,
  defaultValue?: number | undefined
) => number | undefined = (
  number?: number | string,
  decimals: number = 4,
  defaultValue: number | undefined = undefined
) => {
  if (!number) {
    return defaultValue;
  }

  if (typeof number === "string") {
    return formatNumber(parseFloat(number), decimals);
  }

  const result = parseFloat((number ?? 0).toFixed(decimals));

  return isNaN(result) ? 0 : result;
};

export const formatNumberWithoutRounding: (
  number?: number | string,
  decimals?: number
) => number = (number?: number | string, decimals: number = 4) => {
  if (typeof number === "string") {
    return formatNumber(parseFloat(number), decimals);
  }

  let result = parseFloat((number ?? 0).toFixed(8));

  // result = Math.trunc(result * (10 ^ decimals)) / (10 ^ decimals);

  return isNaN(result) ? 0 : result;
};

export const formatNumber: (
  number?: number | string,
  decimals?: number
) => number = (number?: number | string, decimals: number = 4) => {
  if (typeof number === "string") {
    return formatNumber(parseFloat(number), decimals);
  }

  const result = parseFloat((number ?? 0).toFixed(decimals));

  return isNaN(result) ? 0 : result;
};

export const generateString: (length?: number) => string = (
  length: number = 7
) => (Math.random() + 1).toString(36).substring(length);

export const calculateEnergyEntries = (entries: EnergyEntry[]) => {
  let temp: Record<string, any> = {};
  entries.map((entry) => {
    if (!entry.consumption) {
      entry.consumption = 0;
    }

    if (!entry.price) {
      entry.price = 0;
    }

    if (!entry.type || !entry.usage) {
      return;
    }

    if (!temp[entry.type]) {
      temp[entry.type] = {
        energy: 0,
        price: 0,
        average: 0,
        consumptionWithoutLoadProfile: 0,
        consumptionWithLoadProfile: 0,
        consumptionTogether: 0,
        consumptionPrice: 0,
        priceFunding: 0,
        name: entry.type,
        temp_price: 0,
      };
    }

    temp[entry.type] = {
      ...temp[entry.type],
      energy: formatNumber(
        temp[entry.type].energy + formatNumber(entry.consumption, 8),
        8
      ),
      price: formatNumber(
        temp[entry.type].price + formatNumber(entry.price, 8),
        8
      ),
    };

    if (!entry.hideFromStep2) {
      entry.usage.map((usage) => {
        if (!entry.type) {
          return;
        }

        if (entry.special === "false") {
          temp[entry.type].consumptionWithoutLoadProfile += formatNumber(
            usage.consumption,
            8
          );
        } else {
          temp[entry.type].consumptionWithLoadProfile += formatNumber(
            usage.consumption,
            8
          );
        }
        temp[entry.type].temp_price +=
          formatNumber(usage.consumption, 8) * formatNumber(usage.price, 8);

        temp[entry.type].consumptionPrice += formatNumber(usage.price, 8);
      });
    }

    temp[entry.type].consumptionTogether = formatNumber(
      temp[entry.type].consumptionWithLoadProfile +
        formatNumber(temp[entry.type].consumptionWithoutLoadProfile, 8),
      8
    );

    temp[entry.type].priceFunding =
      formatNumber(
        temp[entry.type].price * temp[entry.type].consumptionPrice,
        8
      ) / formatNumber(temp[entry.type].consumptionTogether, 8);

    temp[entry.type].averagePriceFunding =
      temp[entry.type].consumptionTogether > 0
        ? formatNumber(
            formatNumber(temp[entry.type].temp_price, 8) /
              formatNumber(temp[entry.type].consumptionTogether, 8),
            8
          )
        : 0;

    temp[entry.type].average =
      temp[entry.type].consumptionTogether > 0
        ? formatNumber(
            formatNumber(temp[entry.type].price, 8) /
              formatNumber(temp[entry.type].energy, 8),
            8
          )
        : 0;

    if (temp[entry.type].average === 0) {
      temp[entry.type].priceIncrease = 0;
    } else if (
      temp[entry.type].averagePriceFunding > temp[entry.type].average
    ) {
      temp[entry.type].priceIncrease = formatNumber(
        formatNumber(temp[entry.type].averagePriceFunding, 8) -
          formatNumber(temp[entry.type].average, 8),
        8
      );
    } else {
      temp[entry.type].priceIncrease = 0;
    }
  });

  return temp;
};
