import React from 'react'

const footerLeftLinks = [
    {
        name: "Impressum",
        href: "https://www.aws.at/impressum/"

    },
    {
        name: "Datenschutz",
        href: "https://aws.sometis.sk/datenschutz"

    },
    {
        name: "Nutzungsbedingungen",
        href: "https://aws.sometis.sk/nutzungsbedingungen"

    }
]

export const AppFooter = () => (
    <footer className="footer border-t-4 border-primary-500 mt-4 px-6 mt-auto">
        <div className="container flex my-4 flex-col md:flex-row">
            <div className="w-full lg:w-1/2 text-center lg:text-left">
                <ul className="flex flex-col md:flex-row md:gap-x-2 footer__links">
                    {footerLeftLinks &&
                        footerLeftLinks.map((link, id) =>
                            <li key={`FOOTER_LINK-${id}`}>
                                <a
                                    href={link.href}
                                    target="_blank"
                                    title={link.name}
                                    aria-label={link.name}
                                    className='hover:underline hover:underline-offset-1'
                                >
                                    {link.name}
                                </a>
                            </li>
                        )}
                </ul>
            </div>
            <div className="w-full lg:w-1/2 text-center lg:text-right pt-4 md:pt-0">
                <a
                    target="_blank"
                    href="https://www.aws.at"
                    className='hover:underline hover:underline-offset-1'
                    aria-label="© Austria Wirtschaftsservice Gesellschaft mbH"
                >
                    © Austria Wirtschaftsservice Gesellschaft mbH
                </a>
            </div>
        </div>
    </footer>
)
