import React, { createContext, useState, ReactNode, useEffect } from "react";
import { EnergyEntry, EnergyType, FuelEntry } from "../@types";
import { createEnergyEntries, createFuelEntries, emptyEnergyEntry, energyTypes } from "../data";
import { generateString } from "../utils";

const getFromLocalStorage = (key: string, expected: string = '{}') => {
    return JSON.parse(localStorage.getItem(key) ?? expected)
}

const setToLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
}

type EnergyContextProps = {
    children: ReactNode
}

type EnergyContextType = {
    entries: EnergyEntry[]
    refreshKey: string
    fuelEntries: FuelEntry[]
    energyTypes: EnergyType[]
    isResetting: boolean,
    resetEntries: () => void
    addEnergyEntry: (isLinked?: boolean) => void
    updateEnergyEntry: (id: number, entry: EnergyEntry) => void
    updateFuelEntry: (id: number, entry: FuelEntry) => void
    removeEnergyEntry: (id: number) => void
}

const defaultEnergyContextData = {
    entries: [],
    refreshKey: 'firstOne',
    fuelEntries: [],
    energyTypes: [],
    isResetting: false,
    resetEntries: () => { },
    addEnergyEntry: (isLinked?: boolean) => { },
    updateEnergyEntry: (id: number, entry: EnergyEntry) => { },
    updateFuelEntry: (id: number, entry: FuelEntry) => { },
    removeEnergyEntry: (id: number) => { }
}

export const EnergyContext = createContext<EnergyContextType>(defaultEnergyContextData)

export const EnergyContextProvider = ({ children }: EnergyContextProps) => {
    const [refreshKey, setRefreshKey] = useState(generateString())
    const [isResetting, setIsResetting] = useState(false)
    const localStorageEnergyEntries = (
        getFromLocalStorage("EnergyEntries", JSON.stringify(createEnergyEntries()))
    ) as EnergyEntry[]

    const localStorageFuelEntries = (
        getFromLocalStorage("FuelEntries", JSON.stringify(createFuelEntries()))
    ) as FuelEntry[]

    const [energyEntries, setEnergyEntries] = useState<EnergyEntry[]>(localStorageEnergyEntries);
    const [fuelEntries, setFuelEntries] = useState<FuelEntry[]>(localStorageFuelEntries);

    useEffect(() => {
        setToLocalStorage("EnergyEntries", energyEntries)
    }, [energyEntries])

    useEffect(() => {
        setToLocalStorage("FuelEntries", fuelEntries)
    }, [fuelEntries])

    useEffect(() => {
        if (isResetting) {
            setEnergyEntries(createEnergyEntries())
            setFuelEntries(createFuelEntries())
        }
        setIsResetting(false)
    }, [isResetting])

    const resetEntries = () => setIsResetting(true)

    const addEnergyEntry = (isLinked: boolean = true) => setEnergyEntries((entries) => [...entries, { ...emptyEnergyEntry, isLinked, key: generateString() }])

    const updateEnergyEntry = (id: number, entry: EnergyEntry, updateRefreshKey?: boolean) => {
        let newEntries: EnergyEntry[] = energyEntries.map(energyEntry => Object.assign({}, energyEntry))
        newEntries[id] = entry
        setEnergyEntries((newEntries))

        if (updateRefreshKey) {
            setRefreshKey(generateString())
        }
    }

    const updateFuelEntry = (id: number, entry: FuelEntry) => {
        let newEntries: FuelEntry[] = fuelEntries.map(fuelEntry => Object.assign({}, fuelEntry))
        newEntries[id] = entry
        setFuelEntries(newEntries)
    }

    const removeEnergyEntry = (id: number) => {
        setEnergyEntries(energyEntries.filter((energyEntry, entryId) => {
            return entryId !== id
        }))
    }

    return (
        <EnergyContext.Provider
            value={{
                entries: energyEntries,
                fuelEntries,
                energyTypes,
                isResetting,
                refreshKey,
                addEnergyEntry,
                resetEntries,
                updateEnergyEntry,
                updateFuelEntry,
                removeEnergyEntry,
            }}
        >
            {children}
        </EnergyContext.Provider>
    )
}