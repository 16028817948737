import React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import { EnergyContextProvider } from './src/context/EnergyContext'
import { App } from './src/layouts/App'

import './src/styles/global.css'

const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
    element,
}) => {
    return <EnergyContextProvider>
        <App>
            {element}
        </App>
    </EnergyContextProvider>
}

export { wrapPageElement }